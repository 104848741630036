<template>
  <sign-page
    title-text="教育经历"
    :request="request"
    :column-list="columnList"
    @dataFileUpdate="dataFileUpdate"
    table-size="large"
    :title-menus="[{key: 'add', label: '新增'}]"
    :table-actions="tableActions"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :dealFormData="dealFormData"
    :table-actions-fixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerEduRequest as request
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '所属员工',
          key: 'workerId',
          selectDatas: getSelectWorkerList(this),
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '学历',
          selectDatas: this.$store.getters.selectDatas['worker_education_list'],
          key: 'edu',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '学位',
          selectDatas: this.$store.getters.selectDatas['worker_edu_degree'],
          key: 'degree'
        },
        {
          type: 'input',
          label: '毕业院校',
          key: 'school'
        },
        {
          type: 'input',
          label: '学校编码',
          key: 'schoolCode'
        },
        {
          type: 'input',
          label: '专业',
          key: 'pro'
        },
        {
          type: 'datePicker',
          label: '入学时间',
          key: 'startDate'
        },
        {
          type: 'datePicker',
          label: '毕业时间',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '学制',
          key: 'xz'
        },
        {
          type: 'input',
          label: '证书编号',
          key: 'zsCode'
        },
        {
          type: 'select',
          label: '教育形式',
          selectDatas: this.$store.getters.selectDatas['worker_edu_type_list'],
          key: 'jyxs'
        },
        {
          type: 'select',
          label: '学历类型',
          selectDatas: [{key: '第一学历', label: '第一学历'}, {key: '最高学历', label: '最高学历'}, {key: '普通学历', label: '普通学历'}, {key: '最高学历兼第一学历', label: '最高学历兼第一学历'}],
          key: 'type'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '员工工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '学历',
          field: 'edu',
          sort: true,
          filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '学位',
          field: 'degree',
          sort: true,
          filters: this.$store.getters.selectDatas['worker_edu_degree'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '毕业院校',
          field: 'school',
          sort: true
        },
        {
          title: '学校编码',
          field: 'schoolCode',
          sort: true
        },
        {
          title: '专业',
          field: 'pro',
          sort: true
        },
        {
          title: '入学时间',
          field: 'startDate',
          sort: true,
          width: 250,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
          }
        },
        {
          title: '毕业时间',
          field: 'endDate',
          width: 250,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
          }
        },
        {
          title: '学制',
          field: 'xz',
          sort: true
        },
        {
          title: '证书编号',
          field: 'zsCode',
          sort: true
        },
        {
          title: '教育形式',
          field: 'jyxs',
          sort: true,
          filters: this.$store.getters.selectDatas['worker_edu_type_list'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '学历类型',
          field: 'type',
          sort: true,
          filters: [{key: '第一学历', label: '第一学历'}, {key: '最高学历', label: '最高学历'}, {key: '普通学历', label: '普通学历'}, {key: '最高学历兼第一学历', label: '最高学历兼第一学历'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      loadSelectWorkerList(this)
    }
  },
  methods: {
    async loadData (parm) {
      return await loadData(request, this, parm)
    },
    async dataFileUpdate (parm, pageVm) {
      await pageVm.request.update(pageVm.chooseData.id, {
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.startDate && data.endDate && this.timeBj(data.startDate, data.endDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '毕业时间需要大于入学时间。'
        })
        throw Error()
      }
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.img-c {
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    font-size: 1.4rem;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
.preview {
  background-color: #000;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  z-index: 99999;
  cursor: pointer;
}
</style>